<template>
    <div class="income-bets-list">
        <h3>Входящие ставки</h3>

      <div class="row">
        <div class="col-12">
          <div class="form-row align-items-center">
            <div class="col-sm-3 my-1">
              <label class="sr-only" for="inlineFormInputGroupUsername">Поиск</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">Поиск</div>
                </div>
                <input type="text" v-model="searchUser" class="form-control"
                       id="inlineFormInputGroupUsername" placeholder="Поиск">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">

          <table class="table">
              <tr>
                  <th>ID</th>
                  <th>Автор</th>
                  <th>Матч</th>
                  <th>Want Stake, $</th>
                  <th>Bookmaker</th>
                  <th>Тип ставки</th>
                  <th>Время сбытия</th>
                  <th>Создано</th>
              </tr>

              <tr v-for="bet in bets" :key="bet.id">
                  <td>
                      <router-link :to="{ name: 'IncomeBetsSingle', params: { id: bet.id }}">{{ bet.id }}</router-link>
                  </td>
                  <td>{{bet.userName}}</td>
                  <td class="text-left">
                      {{getSportName(bet.sport)}}. {{bet.tournamentName}}<br/>
                      {{bet.home}} - {{bet.away}}<br>
                      ({{bet.eventId}})<br/>
                      Expari betId: {{bet.expariBetId}}
                  </td>
                  <td>{{bet.wantStake}}</td>
                  <td>
                      {{getBookmakerName(bet.bookmakerId)}}
                  </td>
                  <td>{{bet.betName}}<br/>{{bet.period}}</td>
                  <td>{{bet.playAtFmt}}</td>
                  <td>{{bet.createdAtFmt}}</td>
              </tr>
          </table>

          <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                v-on:change="selectPage"
                aria-controls="users-list"
        ></b-pagination>
        </div>
      </div>
    </div>
</template>

<script>
    import IncomeBetService from "../services/IncomeBetService";
    import {BookmakerType, SportType} from "../services/enums"

    export default {
        name: "IncomeBetsList",
        data() {
             return {
                 currentPage: 1,
                 totalRows: 0,
                 perPage:1,
                 bets: [],
                 searchUser: ""
             }
        },
        mounted() {
            this.loadBets()
        },
        methods: {
            selectPage(page) {
                this.currentPage=page;
                this.loadBets()
            },
            loadBets() {
                let self = this;
                IncomeBetService.getIncomeBetsList(self.currentPage-1, self.searchUser)
                    .then((response) => {
                        self.bets = response.data.content;

                        self.currentPage = response.data.number+1;
                        self.totalRows = response.data.totalElements;
                        self.perPage = response.data.size;
                    })
            },
            getBookmakerName(id) {
                return BookmakerType[id];
            },
            getSportName(id) {
                return SportType[id];
            }
        },
        watch: {
          searchUser: function (val) {
            if (val == '' || val.length >=1) {
              this.loadBets();
            }
          }
        },
    }
</script>
